import type { PageView, TrackEvent } from 'src/schemas/application/tracking'

import { ItemCategory } from '@Shared/constants/tracking'

type Tracking = {
  event: (data: TrackEvent) => void
  pageView: (data: PageView) => void
  selectItem: (data: SelectItem) => void
}

type SelectItem = {
  item_id: string
  item_name: string
  item_category: ItemCategory
  item_list_name: string
  index: string
}

const dataLayerReset = {
  event: 'dataLayer reset',
  ecommerce: null,
  spectacle: null,
  place: null,
  page: null,
  article: null,
  user: null
}

const tracking: Tracking = {
  event: function (data) {
    const events =
      typeof window !== 'undefined'
        ? window.dataLayerGTM
        : new Array<TrackEvent>()

    events?.push(data)
  },
  pageView: function (data) {
    this.event(dataLayerReset)
    this.event(data)
  },
  selectItem: function (data) {
    this.event({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: data.item_id,
            item_name: data.item_name,
            item_category: data.item_category,
            index: data.index
          }
        ],
        item_list_name: data.item_list_name
      }
    })
  }
}

export default tracking
