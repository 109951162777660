const colors = [
  'bg-[#9560A4]',
  'bg-[#65320D]',
  'bg-[#FF9900]',
  'bg-[#FBC115]',
  'bg-[#FF6666]',
  'bg-[#00A651]',
  'bg-[#00CC99]'
]

export const userColor = colors[Math.floor(Math.random() * colors.length)]
