'use-client'

import { ComponentProps, useEffect, useState } from 'react'
import Button from 'src/ui/button'

import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'
import { useCityQuery } from '@Shared/hooks/use-city-query'

import Icon from '@Components/icon'

type GPSLocationProps = ComponentProps<'button'>

const GPSLocation = ({ className, ...props }: GPSLocationProps) => {
  const { setCity, city: currentCity } = useCity()
  const [location, setLocation] = useState<{ lat: string; lng: string }>()

  const { data: city } = useCityQuery(
    {
      geolocation: {
        lat: location?.lat ?? '',
        lng: location?.lng ?? ''
      }
    },
    { enabled: Boolean(location) }
  )

  useEffect(() => {
    if (city) {
      const shouldRedirect = city?.urlKey !== currentCity?.urlKey
      setCity(city, shouldRedirect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city])

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert('Geolocalização não é suportada por este navegador')
      return false
    }

    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({
        lat: position.coords.latitude.toString(),
        lng: position.coords.longitude.toString()
      })
    })
  }

  return (
    <Button
      className={cn(
        'flex h-auto cursor-pointer items-center gap-1 px-0 py-4 text-base leading-none text-ing-blue-400',
        className
      )}
      onClick={getLocation}
      variant="ghost"
      {...props}
    >
      <Icon
        className="[&_path]:fill-ing-blue-400 [&_svg]:size-6"
        src="/images/gps.svg"
      />
      Atualizar localização por GPS
    </Button>
  )
}

export default GPSLocation
