import { ComponentProps, useEffect } from 'react'

import { userColor } from '@Shared/constants/user-avatar-color'
import { cn } from '@Shared/helpers/util'
import useLocalStorage from '@Shared/hooks/use-local-storage'
import useUserData from '@Shared/hooks/use-user-data'

import Icon from '@Components/icon'

type UserAvatarProps = ComponentProps<'div'>

const UserAvatar = ({ className, ...props }: UserAvatarProps) => {
  const { user } = useUserData()
  const userLoggedIn = Boolean(user?.token)

  const userFirstName = user?.name?.split(' ')[0] ?? ''
  const userFirstLetter = userFirstName[0]

  const [avatarColor, setAvatarColor] = useLocalStorage<string>(
    'avatar-bg',
    userColor
  )

  useEffect(() => {
    setAvatarColor(avatarColor)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={cn(
        'flex items-center gap-3 text-left text-ing-blue-200',
        className
      )}
      {...props}
    >
      {!userLoggedIn ? (
        <>
          <Icon
            src="/images/user-2.svg"
            className="size-6 cursor-pointer fill-ing-blue-200 md:size-9"
          />
          <p className="hidden w-24 whitespace-pre-wrap text-sm leading-none md:block">
            Entre ou Cadastre-se
          </p>
        </>
      ) : (
        <>
          <div
            className={cn(
              'text-md flex size-6 items-center justify-center rounded-full text-base font-semibold text-white md:size-9',
              avatarColor
            )}
          >
            {userFirstLetter}
          </div>
          <div className="hidden flex-col font-uol-bold md:flex">
            <p className="bg-ing-gradient-yellow mr-[10px] hidden max-w-[150px] bg-clip-text text-base text-transparent md:block">
              Olá, {userFirstName}
            </p>
            <p className="hidden text-xs md:block">Meus Pedidos</p>
          </div>
        </>
      )}
    </div>
  )
}

export default UserAvatar
