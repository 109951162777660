import axios, { AxiosError } from 'axios'

import { getUserData, handleRefreshToken } from '@Shared/helpers/auth'

const BASE_URL = process?.env?.AUTH_URL

const authAPI = axios.create({ baseURL: BASE_URL })

authAPI.interceptors.request.use((config) => {
  const { token } = getUserData()

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

authAPI.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => handleRefreshToken(authAPI, error)
)

export default authAPI
