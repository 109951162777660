'use client'

import Image, { ImageProps as ImgProps } from 'next/image'
import { useEffect, useState } from 'react'

export interface ImageProps extends ImgProps {
  fallback?: string
}

const Component = ({ fallback = '', src, alt, ...rest }: ImageProps) => {
  const [source, setSource] = useState(src)

  useEffect(() => {
    setSource(src)
  }, [src])

  return (
    <Image
      alt={alt}
      src={source}
      onError={() => setSource(fallback)}
      {...rest}
    />
  )
}

export default Component
