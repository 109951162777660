'use-client'

import { ComponentProps } from 'react'

import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'

import { City } from '@Schemas/domain/state'

import tracking from '@Services/tracking'

import Icon from '@Components/icon'

type CityHistoryProps = ComponentProps<'ul'>

const CityHistory = ({ className, ...props }: CityHistoryProps) => {
  const { cityHistory, setCity, city: currentCity } = useCity()

  const handleLocationTracking = (label) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: 'Location',
      event_action: 'Click on Recent City',
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  const handleClick = (city: City) => {
    const selectedCity = cityHistory?.find(
      (previousCity) => previousCity?.urlKey === city?.urlKey
    ) as City

    const shouldRedirect = selectedCity?.urlKey !== currentCity?.urlKey

    handleLocationTracking(selectedCity?.name)
    setCity(selectedCity, shouldRedirect)
  }

  return (
    Boolean(cityHistory.length) && (
      <ul
        className={cn('flex flex-col-reverse gap-3', className)}
        {...props}
        data-testid="city-history"
      >
        {cityHistory.map((city) => (
          <li
            className="flex w-full cursor-pointer gap-2 leading-none text-ing-blue-400"
            key={city.id}
            onClick={() => handleClick(city)}
          >
            <Icon
              src="/images/recent.svg"
              className="[&_path]:fill-ing-blue-400 [&_svg]:h-5 [&_svg]:w-5"
            />
            {city.name}
          </li>
        ))}
      </ul>
    )
  )
}

export default CityHistory
