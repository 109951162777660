'use-client'

import { ComponentProps } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import Button from 'src/ui/button'

import useCity from '@Shared/hooks/use-city'
import { useStateQuery } from '@Shared/hooks/use-state-query'

import { City } from '@Schemas/domain/state'

import tracking from '@Services/tracking'

import Icon from '@Components/icon'

type CitySelectorForm = { state: string; city: string }
type CitySelectorProps = ComponentProps<'form'>

const CitySelector = ({ ...props }: CitySelectorProps) => {
  const methods = useForm<CitySelectorForm>({
    defaultValues: { state: 'Estado', city: 'Cidade' }
  })

  const { register, handleSubmit, setValue, control } = methods

  const { data: states } = useStateQuery()

  const { setCity, city: currentCity } = useCity()

  const onSubmit = (data) => {
    const { state, city } = data

    if (state === 'Estado' || city === 'Cidade') {
      alert('Selecione uma opção válida')
      return
    }

    const newCity = currentState?.cities.find(
      (current) => current.name === city
    ) as City

    const shouldRedirect = newCity?.urlKey !== currentCity?.urlKey

    handleLocationTracking(newCity.name)
    setCity(newCity, shouldRedirect)
  }

  const handleLocationTracking = (label) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: 'Location',
      event_action: 'Click on Change City',
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  const watchState = useWatch({
    control,
    name: 'state'
  })

  const currentState = states?.find((state) => state?.name === watchState)

  return (
    <FormProvider {...methods}>
      <form aria-label="form" {...props}>
        <div className="flex flex-col gap-3 pt-3">
          <div className="relative">
            <select
              {...register('state', {
                onChange: () => setValue('city', 'Cidade')
              })}
              className="h-12 w-full cursor-pointer appearance-none rounded border-l-4 border-l-[#999] pl-3 text-black outline-none focus:border-ing-blue-400"
              aria-label="Estado"
            >
              <option value="Estado">Estado</option>
              {states?.map((state) => (
                <option key={state.uf} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
            <Icon
              src="/images/arrow-right.svg"
              className="absolute right-3 top-[calc(50%_-_6px)] z-10 cursor-pointer [&_svg]:h-3 [&_svg]:w-3 [&_svg]:rotate-90 [&_svg]:fill-ing-blue"
            />
          </div>
          <div className="relative">
            <select
              {...register('city')}
              className="h-12 w-full cursor-pointer appearance-none rounded border-l-4 border-l-[#999] pl-3 text-black outline-none focus:border-ing-blue-400"
              aria-label="Cidade"
            >
              <option value="Cidade">Cidade</option>
              {currentState?.cities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            <Icon
              src="/images/arrow-right.svg"
              className="absolute right-3 top-[calc(50%_-_6px)] z-10 cursor-pointer [&_svg]:h-3 [&_svg]:w-3 [&_svg]:rotate-90 [&_svg]:fill-ing-blue"
            />
          </div>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            Trocar Cidade
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default CitySelector
