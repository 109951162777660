import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import { cn } from '@Shared/helpers/util'

const buttonVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'font-uol',
    'inline-flex',
    'items-center',
    'gap-2',
    'whitespace-nowrap',
    'focus-visible:outline-none',
    'focus-visible:ring-1',
    'focus-visible:ring-ring',
    'disabled:pointer-events-none',
    'hover:opacity-70'
  ],
  {
    variants: {
      variant: {
        default:
          'text-white bg-ing-gradient-blue disabled:bg-ing-neutral-200 disabled:bg-none disabled:text-ing-neutral-600/50',
        secondary:
          'text-black bg-ing-gradient-yellow disabled:bg-ing-neutral-200 disabled:bg-none disabled:text-ing-neutral-600/50',
        outline:
          'border-ing-blue-400 text-ing-blue-400 border-2 bg-transparent disabled:border-ing-neutral-200 disabled:text-ing-neutral-200',
        ghost:
          'text-ing-blue-400 border-none bg-transparent disabled:text-ing-neutral-200/50'
      },
      size: {
        lg: 'h-14 px-14 text-2xl rounded-[14px]',
        default: 'h-12 px-12 text-xl rounded-xl',
        sm: 'h-10 px-10 text-base rounded-[10px]',
        xs: 'h-8 px-8 text-xs rounded-lg'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Component = asChild ? Slot : 'button'
    return (
      <Component
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export default Button
