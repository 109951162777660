import * as PopoverPrimitive from '@radix-ui/react-popover'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { cn } from '@Shared/helpers/util'

const Content = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ children, className, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    className={cn(
      'w-screen bg-ing-neutral-600 p-6 sm:p-8 md:w-full md:rounded-2xl',
      className
    )}
    {...props}
  >
    {children}
  </PopoverPrimitive.Content>
))
Content.displayName = PopoverPrimitive.Content.displayName

const Arrow = forwardRef<
  ElementRef<typeof PopoverPrimitive.Arrow>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ children, className, ...props }, ref) => (
  <PopoverPrimitive.Arrow
    ref={ref}
    width={20}
    height={10}
    className={cn('fill-ing-neutral-600', className)}
    {...props}
  />
))
Arrow.displayName = PopoverPrimitive.Arrow.displayName

const Popover = {
  Root: PopoverPrimitive.Root,
  Trigger: PopoverPrimitive.Trigger,
  Content: Content,
  Arrow: Arrow
}

export default Popover
