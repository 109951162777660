'use client'

import { useCallback, useEffect, useState } from 'react'
import { User, UserCookie } from 'src/schemas/application/user'
import { createContext } from 'use-context-selector'

import {
  ACCESS_TOKEN,
  DEFAULT_USER_DATA,
  KEEP_CONNECTED,
  REFRESH_TOKEN
} from '@Shared/constants/application'
import { setUserCookies } from '@Shared/helpers/auth'
import { useRefreshTokenQuery } from '@Shared/hooks/use-refresh-token-query'
import { useUserQuery } from '@Shared/hooks/use-user-query'

import { cookies } from '@Services/cookies'

type UserContext = {
  user: User
  onSetUser: (userData: User) => void
  data: User
  isFetchingUser: boolean
}

export const UserContext = createContext<UserContext | null>(null)

export const UserProvider = ({ children }) => {
  const hasAccessToken = Boolean(cookies.get(ACCESS_TOKEN))
  const hasKeepConnectedCookie = JSON.parse(cookies.get(KEEP_CONNECTED) ?? null)
  const hasRefreshToken = Boolean(cookies.get(REFRESH_TOKEN))

  const shouldRefreshToken = Boolean(
    hasKeepConnectedCookie && hasRefreshToken && !hasAccessToken
  )

  const [user, setUser] = useState<User>(() => {
    if (hasAccessToken) {
      const userCookie = JSON.parse(cookies.get(ACCESS_TOKEN)) as UserCookie

      return {
        id: userCookie.user_id,
        token: userCookie.access_token,
        name: userCookie.user_first_name,
        email: '',
        cpf: ''
      }
    }

    return DEFAULT_USER_DATA
  })

  const {
    data,
    isError,
    isFetching: isFetchingUser
  } = useUserQuery(user?.id, {
    enabled: Boolean(user.id)
  })

  const { data: userData, isError: isRefreshTokenError } = useRefreshTokenQuery(
    {
      enabled: shouldRefreshToken
    }
  )

  const onSetUser = useCallback((userData: User) => {
    setUser(userData)
  }, [])

  useEffect(() => {
    const hasUserData = Boolean(userData)
    hasUserData &&
      setUserCookies(
        {
          id: userData?.user_id,
          name: userData?.user_first_name,
          token: userData?.access_token
        },
        {
          refreshToken: userData?.refresh_token,
          expiration: userData?.expiration
        }
      )
    hasUserData &&
      setUser({
        id: userData?.user_id,
        token: userData?.access_token,
        name: userData?.user_first_name
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  // Destroy cookies when user is not authenticated
  useEffect(() => {
    const shouldResetUserData = isError || isRefreshTokenError
    isError && cookies.destroy(ACCESS_TOKEN)
    isRefreshTokenError && cookies.destroy(REFRESH_TOKEN)
    shouldResetUserData && setUser(DEFAULT_USER_DATA)
  }, [isError, isRefreshTokenError])

  return (
    <UserContext.Provider
      value={{
        user,
        onSetUser,
        data,
        isFetchingUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
