'use client'

import { usePathname } from 'next/navigation'
import ActiveEventsIcon from 'public/images/calendar-star-gradient.svg'
import EventsIcon from 'public/images/calendar-star.svg'
import ActiveMoviesIcon from 'public/images/clapperboard-gradient.svg'
import MoviesIcon from 'public/images/clapperboard.svg'
import ActiveHomeIcon from 'public/images/home-gradient.svg'
import HomeIcon from 'public/images/home.svg'
import ActiveNewsIcon from 'public/images/news-gradient.svg'
import NewsIcon from 'public/images/news.svg'
import ActiveTheatersIcon from 'public/images/room-gradient.svg'
import TheatersIcon from 'public/images/room.svg'
import ActiveTheatreIcon from 'public/images/theatre-gradient.svg'
import TheatreIcon from 'public/images/theatre.svg'
import { FC, SVGProps } from 'react'

import { eventCategory } from '@Shared/helpers/tracking'
import { cn } from '@Shared/helpers/util'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

type NavbarItems = {
  icon: FC<SVGProps<SVGElement>>
  activeIcon: FC<SVGProps<SVGElement>>
  name: string
  url: string
}

export const navbarItems: NavbarItems[] = [
  {
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    name: 'Início',
    url: '/'
  },
  {
    icon: MoviesIcon,
    activeIcon: ActiveMoviesIcon,
    name: 'Filmes',
    url: '/filmes'
  },
  {
    icon: TheatersIcon,
    activeIcon: ActiveTheatersIcon,
    name: 'Cinemas',
    url: '/cinemas'
  },
  {
    icon: TheatreIcon,
    activeIcon: ActiveTheatreIcon,
    name: 'Teatro',
    url: '/teatros'
  },
  {
    icon: EventsIcon,
    activeIcon: ActiveEventsIcon,
    name: 'Eventos',
    url: '/eventos'
  },
  {
    icon: NewsIcon,
    activeIcon: ActiveNewsIcon,
    name: 'Notícias',
    url: '/noticias'
  }
] as const

const Navbar = () => {
  const path = usePathname()
  const pageName = usePageName()

  const isActive = (url: string) => path === url

  const handleTracking = () => {
    tracking.event({
      event: 'gaEventPush',
      event_category: eventCategory(pageName),
      event_action: `Click on ${pageName} page`,
      event_label: '',
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <nav
      aria-label="Menu Principal Mobile"
      className="fixed bottom-0 z-10 w-full bg-ing-neutral-700 text-ing-blue-400 lg:hidden"
    >
      <ul className="flex w-full justify-around">
        {navbarItems.map((item) => {
          const Icon = isActive(item.url) ? item.activeIcon : item.icon

          return (
            <li
              key={item.name}
              className={cn('relative', isActive(item.url) && 'active')}
              onClick={handleTracking}
            >
              <Link
                href={item.url}
                className="flex h-14 w-12 flex-col items-center justify-center gap-1"
                data-testid={`navbar-link-${item.name}`}
              >
                <Icon
                  className={cn(
                    'flex size-6 justify-center',
                    isActive(item.url) && 'active'
                  )}
                  data-testid="navbar-icon"
                />
                <span
                  className={cn(
                    'text-xs text-ing-neutral-200',
                    isActive(item.url) &&
                      'bg-ing-gradient-yellow bg-clip-text font-uol-bold text-transparent'
                  )}
                >
                  {item.name}
                </span>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navbar
