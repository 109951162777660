import { useContextSelector } from 'use-context-selector'

import { UserContext } from '@Context/user-context'

const useUserData = () => {
  const user = useContextSelector(UserContext, (context) => context?.user)
  const data = useContextSelector(UserContext, (context) => context?.data)
  const isFetchingUser = useContextSelector(
    UserContext,
    (context) => context?.isFetchingUser
  )

  return { user, data, isFetchingUser }
}

export default useUserData
